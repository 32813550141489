import { config } from './config';
import axios from 'axios';

const appEnv = process.env.REACT_APP_ECS_ENV ? process.env.REACT_APP_ECS_ENV : process.env.REACT_APP_APP_ENV;
const apiKey = config.secrets.apiKey[appEnv];
const requestUrl = config.urlForAPI[appEnv];

export const signInService = async (formData) => {
    try {
        let options = {
            headers: {
                apikey: apiKey,
                'Content-Type': 'multipart/form-data'
            }
        };

        const sendRequest = await axios.post(`${requestUrl}smr/authorize`, formData, options);

        return sendRequest.data;
    } catch (error) {
        if (!error.response) {
            return error.message;
        } else {
            return error.response.data;
        }
    }
};

export const uploadService = async (formData, filingKey) => {
    try {
        let headerOptions = {
            headers: {
                apikey: apiKey,
                filingKey: filingKey,
                'Content-Type': 'multipart/form-data'
            }
        };

        const sendRequest = await axios.post(`${requestUrl}smr/upload`, formData, headerOptions);
        return sendRequest.data;
    } catch (error) {
        if (!error.response) {
            return error.message;
        } else {
            return error.response.data;
        }
    }
};
