import React from 'react'
import ErrDesign from '../../img/errorDesign.svg';
import '../../css/errorPage.css'
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
    	<>
			<h1 className="header">
			<img className="notfound-img" src={ErrDesign} alt="error" />
			<br></br>
				Oops!
			</h1>
			<h2 className="notification">
				Error Code: 404
			</h2>
			<br></br>
			<p className='notification'>The page you are trying to reach is unavailable. Contact us at <a href="mailto:support@trajector.com">support@trajector.com</a> if you can't find what you need.</p>
			<Link to="/login">
				<button className='button'>Return to Login</button>
			</Link>
      	</>
    );
}

export default NotFound
