import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './css/app.css';
import './css/coolicons.css';
import './css/fonts.css';
import Signin from './components/users/Signin';
import SmrUploader from './components/SmrUploader';
import NotFound from './components/error/notFound';
import ErrorPage from './components/ErrorPage';
import UploadConfirmation from './components/UploadConfirmation';
import TrajectorLogo from './img/Trajector Services Wordmark Logo.svg';
import { v4 as uuidv4 } from 'uuid';

const App = () => {
  const [isVerified, setIsVerified] = useState(null);

  /**
   * see if user is authorized when component updates
   */
  useEffect(() => {
    let auth = window.sessionStorage.getItem('auth');
    if (!auth) {
      setIsVerified(false);
    } else {
      setIsVerified(true);
    }
  }, [isVerified]);

  /**
   * Handle when user signs out
   */
  const handleSignOut = () => {
    window.sessionStorage.removeItem('auth');
    window.location.href = '/';
  };

  return (
    <Router>
      <div className="mainContainer">
        <nav className="navbar navbar-expand-lg navbar-light bg-light custom-navbar">
		<a className="navbar-brand" href="#navtop">
              <img src={TrajectorLogo} alt="Trajector Logo" className="logo" />
            </a>
           
			{isVerified ? (
                 <button
					className="navbar-toggler"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent"
					aria-expanded="false"
					aria-label="Toggle navigation"
			   	>
					 <span className="navbar-toggler-icon"></span>
			   	</button>
                ) : (
                  ''
                )}
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              
                {isVerified ? (
                 <ul className="navbar-nav">
                    <li className="nav-item" key={uuidv4()}>
                      <button
                        className="custom-nav-btn"
                        onClick={handleSignOut}
                      >
                        Sign Out
                      </button>
                    </li>
				  </ul>
                ) : (
                  ''
                )}
             
            </div>
        </nav>
        <div className="container-fluid main-wrapper-container">
          <main>
            <div className="row">
              <div className="col-xl-4 col-sm-12 col-md-8 mx-auto main-col">
                <Routes>
                  <Route path="/smruploader" element={<SmrUploader />}></Route>
                  <Route
                    path="/uploadconfirmation"
                    element={<UploadConfirmation />}
                  ></Route>
                  <Route
                    path="/:userid"
                    element={<Signin />}
                  ></Route>
                  {['//', '*'].map((path) => (
            <Route path={path} element={<NotFound />} />
  ))}
                  <Route path="/" element={<ErrorPage />}></Route>
                  
                </Routes>
              </div>
            </div>
          </main>
        </div>

        <footer>
          <div className="container static-bottom">
            <div className="row justify-content-center">
              <div className="col-10 mx-auto">
                <p className="text-center">&copy; Trajector Services</p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </Router>
  );
};

export default App;
