import React from 'react';
import SuccessImg from '../img/Success-vector.svg';

const UploadConfirmation = () => {
  return (
    <div className="p-5">
      <h2 className="text-center" id="successHeader">
        <img className="success-img" src={SuccessImg} alt="Success" />
        <br></br>
        Success
      </h2>
      <p className="text-center">
        Files have successfully been uploaded! An additional confirmation will
        be sent to your email address. Thank you, and have a wonderful day!
      </p>
      <p className="text-center">
        <a href="/smruploader">Submit another file</a>
      </p>
    </div>
  );
};
export default UploadConfirmation;
