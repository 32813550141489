import React from 'react';
import ErrorImg from '../img/Error-Vector.svg';

const ErrorPage = () => {
  return (
    <div className="p-5">
      <h2 className="text-center" id="errorHeader">
        <img className="error-img" src={ErrorImg} alt="error" />
        <br></br>
        Error
      </h2>
      <p className="text-center mt-4">
        The page you are trying to reach is unavailable.
      </p>
    </div>
  );
};
export default ErrorPage;
