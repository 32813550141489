import React, { useEffect, useState } from 'react';
import { signInService } from '../../services';

const Signin = () => {
  const [password, setPassword] = useState('');
  const [passwordLoginError, setPasswordError] = useState('');
  const [submitBtnIsDisabled, setSubmitBtnIsDisabled] = useState(true);
  const [submitBtnCss, setSubmitBtnCss] = useState(null);

  const onPasswordChange = (event) => {
    let passwordValue = event.target.value;
    setPassword(passwordValue);
    setPasswordError('');
    if (passwordValue === '') {
      setSubmitBtnIsDisabled(true);
      setSubmitBtnCss({ backgroundColor: '#184897' });
    } else {
      setSubmitBtnIsDisabled(false);
      setSubmitBtnCss(null);
    }
  };

  const onFormSubmit = async (event) => {
    event.preventDefault();

    let formData = new FormData();

    if (window.location.pathname.slice(1) === '') return false;
    let pathName = document.querySelector('#id').value;
    formData.append('id', pathName);
    formData.append('password', password);
    try {
      await signInService(formData).then((res) => {
        if (res.error) {
          setPasswordError(res.error);
          setPassword('');
          setSubmitBtnIsDisabled(true);
          return false;
        }

        // success
        if (res.verified) {
          window.sessionStorage.setItem('auth', true);
          window.sessionStorage.setItem('clientAccount', res.clientAccount);
          window.sessionStorage.setItem('employeeName', res.employeeName);
          window.sessionStorage.setItem('employeeEmail', res.employeeEmail);
          window.sessionStorage.setItem('clientName', res.clientName);
          window.sessionStorage.setItem('clientEmail', res.clientEmail);
          window.sessionStorage.setItem(
            'clientFilingKey',
            res.clientFilingKey
          );

          window.location.href = '/smruploader';
        }
      }).catch((error) => {
        console.log(error);
      });
    } catch (error) {
      setPasswordError(error);
    }
  };

  return (
   <div className="signin-container">
	      <h2 className="text-center">Access the Secure Document Uploader</h2>
      <p className="text-center">
        Please enter the one-time password we sent to your email address.
      </p>
      <form onSubmit={onFormSubmit} className="mt-5">
        <input type="hidden" id="id" name="id" value={window.location.pathname.slice(1)} />
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            className="form-control border-change"
            name="password"
            required={true}
            value={password}
            onChange={onPasswordChange}
          />
          <div className="printError text-danger">{passwordLoginError}</div>
        </div>

        <div className="form-group">
          <button
            type="submit"
            className="btn btn-primary submit-button"
            id="submitBtn"
            disabled={submitBtnIsDisabled}
            style={submitBtnCss}
          >
            Continue
          </button>
        </div>
      </form>
   </div>
  );
};

export default Signin;
